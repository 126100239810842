<template>
  <v-sheet elevation="2" class="pa-lg-7 pa-3 my-10 block" id="admission">
    <div class="text-center my-5 block">
      <h4 class="font-weight-bold text-medium-emphasis text-h5 text-lg-h4">
        Tutor Request Process
      </h4>
      <p class="text-subtitle-1 text-medium-emphasis">Three Simple Steps</p>
    </div>
    <v-timeline
      truncate-line="both"
      line-inset="12"
      :direction="$vuetify.display.mobile ? 'vertical' : 'horizontal'"
      :side="$vuetify.display.mobile ? 'end' : ''"
    >
      <v-timeline-item
        dot-color="green-darken-3"
        icon="mdi-monitor-account"
        size="x-large"
      >
        <!-- <template v-slot:opposite> 1 </template> -->
        <v-card elevation="7" class="pa-3 text-center text-lg-start">
          <div class="text-h6 font-weight-bold text-medium-emphasis my-3">
            Step One
          </div>
          <p>
            Fill our free online tutor
            <NuxtLink to="/form">request form</NuxtLink> Or call us directly for
            a paid in person registration.
          </p>
        </v-card>
      </v-timeline-item>

      <v-timeline-item
        icon="mdi-file-document-multiple"
        dot-color="green-darken-3"
        size="x-large"
      >
        <!-- <template v-slot:opposite> 2 </template> -->
        <v-card elevation="7" class="pa-3 text-center text-lg-start">
          <div class="text-h6 font-weight-bold text-medium-emphasis my-3">
            Step Two
          </div>
          <p>
            Provide your child's information through our
            <NuxtLink to="/form">request form</NuxtLink> or to the registration
            officer.
          </p>
        </v-card>
      </v-timeline-item>

      <v-timeline-item
        dot-color="green-darken-3"
        icon="mdi-account-school"
        size="x-large"
      >
        <!-- <template v-slot:opposite> 3 </template> -->
        <v-card elevation="7" class="pa-3 text-center text-lg-start">
          <div class="text-h6 font-weight-bold text-medium-emphasis my-3">
            Step Three
          </div>
          <p>
            Get ready to get started. Receive your home tutor and let's start
            the tuition.
          </p>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-sheet>
</template>

<script>
export default {
  name: "Testimonials",
};
</script>

<style scoped></style>
