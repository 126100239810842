<template>
  <div class="mb-5">
    <div class="py-5">
      <h2 class="mb-0 choose-title">HOME OF SOLUTIONS</h2>
      <h6 class="fs-beauty text-body-1 mt-0">
        We provide the following services
      </h6>
    </div>
    <div class="d-flex justify-center">
      <v-slide-group class="py-2">
        <v-slide-group-item>
          <v-card class="service-Card" elevation="4">
            <nuxt-img
              class="service-image"
              src="/images/student2.png"
              alt=""
              loading="lazy"
              format="webp"
            ></nuxt-img>
            
            <NuxtLink class="text-white" to="/tuition">
              <v-btn color="green-darken-4" size="large" class="my-2 fw-bolder">
                Home Tuition
              </v-btn>
            </NuxtLink>
            <p class="service-text text-dark-emphasis my-2 px-lg-2 px-4">
              Get a home teacher for your child and help him become his very
              best.
            </p>
          </v-card>
        </v-slide-group-item>

        <v-slide-group-item>
          <v-card class="service-Card" elevation="4">
            <nuxt-img
              class="service-image"
              src="/images/counsel1.png"
              alt="images/skill"
              format="webp"
              loading="lazy"
            ></nuxt-img>
            <NuxtLink class="text-white" to="/counselling">
              <v-btn color="green-darken-4" size="large" class="my-2 fw-bolder">
                Counseling
              </v-btn>
            </NuxtLink>

            <p class="service-text text-dark-emphasis my-2 px-lg-2 px-4">
              Receive expert assistance to make the right decisions for the
              future. Avoid the pitfalls.
            </p>
          </v-card>
        </v-slide-group-item>

        <v-slide-group-item>
          <v-card class="service-Card" elevation="4">
            <nuxt-img
              class="service-image"
              src="/images/skill1.png"
              alt="images"
              loading="lazy"
              format="webp"
            ></nuxt-img>

            <NuxtLink class="text-white" to="/consultancy">
              <v-btn color="green-darken-4" size="large" class="my-2 fw-bolder">
                Consultancy
              </v-btn>
            </NuxtLink>

            <p class="service-text text-dark-emphasis my-2 px-lg-2 px-4">
              Get in touch with the industry experts. Let us move your
              organization the next level.
            </p>
          </v-card>
        </v-slide-group-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  data() {
    return {};
  },
};
</script>

<style scoped>
.service-Card {
  width: 18rem;
  margin: 3rem;
}

.service-image {
  width: 12rem;
}

@media (max-width: 1028px) {
  .service-image {
    width: 10rem;
  }

  .service-Card {
    width: 14rem;
    margin: 0.5rem;
  }
}
</style>
