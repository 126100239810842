<template>
  <div>
    <v-parallax
      gradient="to bottom, rgba(0,0,0,.7), rgba(0,0,0,.5)"
      :height="height"
      :src="image"
      class="d-flex align-center justify-center"
    >
      <div class="text-white">
        <h1 class="text-lg-h3 text-h5 font-weight-bold">{{ title }}</h1>
        <h5 class="text-lg-h5 text-h6">{{ content }}</h5>
      </div>
    </v-parallax>
  </div>
</template>

<script>
export default {
  name: "Parallax",
  data() {
    return {};
  },
  props: {
    image: String,
    title: String,
    content: String,
    height: [String, Number],
  },
};
</script>

<style scoped></style>
