<template>
  <div class="d-flex flex-column align-center py-12">
    <h1 class="text-h4 font-weight-bold text-medium-emphasis">Testimonials</h1>
    <v-container>
      <v-row>
        <v-col
          v-for="(testimonial, index) in testimonials"
          :key="index"
          cols="12"
          md="4"
        >
          <v-card elevation="5" class="mx-auto" max-width="400">
            <v-card-title class="text-medium-emphasis my-0 pt-3">{{
              testimonial.parent
            }}</v-card-title>
            <v-card-subtitle class="my-0 py-0">{{ testimonial.occupation }}</v-card-subtitle>
            <v-card-text>
              <p class="text-body-1 py-4">{{ testimonial.quote }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "TestimonialComponent",
  data() {
    return {
      testimonials: [
        {
          parent: "Mr. Munkaila",
          occupation: "Businessman",
          quote:
            "Lifeline is teaching reading to my children. The tutor assigned to me doing a good job and I find their service to be affordable. I commend Lifeline for the good job they are doing.",
        },
        {
          parent: "Madam Beatrice",
          occupation: "Army Officer",
          quote:
            "My daughters are being taught by two tutors from Lifeline. I appreciate their professionalism and dedication. My daughters enjoy their teaching and have improved greatly.",
        },
        {
          parent: "Mr. Kofi",
          occupation: "Civil Servant",
          quote:
            "My son is currently preparing for the Basic Edu. Certificate Exam. (B.E.C.E). Lifeline provided two tutors for different subjects and their prices are affordable. I highly recommend them to you too.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.parent-quote {
  font-size: 20px;
  line-height: 1.5;
  padding: 5rem 8rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .parent-quote {
    font-size: 12px;
    line-height: 1.5;
    padding: 0.5rem;
  }
}
</style>
