<template>
  <div class="">
    <NavBar></NavBar>
    <v-carousel hide-delimiters show-arrows="hover" class="h-screen">
      <v-carousel-item cover class="banner">
        <div class="h-100 d-flex align-center justify-center">
          <div class="pa-5">
            <h1
              class="text-lg-h3 text-h4 font-weight-bold text-white mt-lg-5 mt-5 mb-0 animate__animated animate__backInDown"
            >
              MY
              <span class="text-green-darken-1">HOME </span>TEACHER
            </h1>
            <p
              class="text-lg-h5 text-body-1 text-white animate__animated animate__bounceInRight"
            >
              Get your child a professional and qualified home teacher
              <span class="d-lg-block d-md-block">
                and achieve the results you desire.
              </span>
            </p>
  
            <div class="d-lg-flex mb-9">
              <v-select
                variant="solo"
                multiple
                placeholder="Select Tuition Subjects"
                chips
                density="compact"
                :items="['English Lang.', 'Mathematics', 'Science', 'Other']"
              ></v-select>
              <NuxtLink to="/form" class="text-white">
                <v-btn
                  color="green-darken-4"
                  size="large"
                  variant="elevated"
                  append-icon="mdi-arrow-right-circle"
                  class="px-lg-2 px-5"
                >
                  request
                </v-btn>
              </NuxtLink>
            </div>
            <v-card
              variant="outlined"
              class="d-lg-flex d-md-flex d-none justify-space-around text-white"
            >
              <div class="text-white pa-3">
                <CountUp class="text-h6" :endValue="201" :duration="3000" />
                <h5>Students</h5>
              </div>
              <div class="text-white pa-3">
                <CountUp class="" :endValue="501" :duration="3000" />
                <h5>Lessons</h5>
              </div>
  
              <div class="text-white pa-3">
                <CountUp class="" :endValue="81" :duration="3000" />
                <h5>Tutors</h5>
              </div>
            </v-card>
          </div>
        </div>
      </v-carousel-item>
  
      <v-carousel-item cover class="counsel-banner">
        <div class="h-100 d-flex justify-center align-center">
          <div class="pa-5">
            <h1
              class="text-lg-h3 text-h4 text-white font-weight-bold mb-0 animate__animated animate__backInDown"
            >
              NEED OF
              <span class="text-green-darken-1"
                >GUIDANCE?
                <i
                  class="fa text-body-1 text-white fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
              </span>
            </h1>
            <p
              class="text-lg-h5 text-h6 text-white fs-5 mt-0 animate__animated animate__bounceInRight"
            >
              Speak to a professional counsellor to assist you with your
              challenge.
            </p>
            <div class="d-lg-flex mb-9">
              <v-select
                variant="solo"
                multiple
                placeholder="Select Service"
                chips
                density="compact"
                :items="[
                  'Behavioral Change Counselling',
                  'Educational Counselling',
                  'Crisis Counselling',
                ]"
              ></v-select>
              <v-btn color="green-darken-4" size="large">
                <NuxtLink id="bookBtn" to="/counselling" class="text-white"
                  >BOOK A SESSION</NuxtLink
                >
              </v-btn>
            </div>
          </div>
        </div>
      </v-carousel-item>
  
      <v-carousel-item cover class="consult-banner">
        <div class="h-100 d-flex justify-center align-center">
          <div class="pa-5">
            <h1
              class="text-lg-h3 text-h4 font-weight-bold text-white mt-lg-5 mt-5 mb-0 animate__animated animate__backInDown"
            >
              GROW YOUR
              <span class="text-green-darken-1 shadow-two">SCHOOL</span>
            </h1>
            <p
              class="text-lg-h5 text-h6 text-white animate__animated animate__bounceInRight"
            >
              <span class="d-none d-lg-flex d-md-flex"
                >Consult with us and let us help your school reach its
                potential.</span
              >
              We are the professionals -- Teacher Recruitment, Teacher Monitoring,
              <br />
              School Branding, et al
            </p>
            <div class="d-lg-flex mb-9">
              <v-select
                variant="solo"
                multiple
                placeholder="Select Service"
                chips
                density="compact"
                :items="[
                  'Teacher Recruitment',
                  'Teacher Monitoring',
                  'School Branding',
                  'Website Creation',
                ]"
              ></v-select>
              <v-btn color="green-darken-4" size="large">
                <NuxtLink class="text-white" id="bookBtn" to="/consultancy"
                  >LET'S START...</NuxtLink
                >
              </v-btn>
            </div>
          </div>
        </div>
      </v-carousel-item>
    </v-carousel>

  </div>
</template>

<script>
export default {
  name: "Carousel",
};
</script>

<style scoped>
.parent-quote {
  font-size: 20px;
  line-height: 1.5;
}

.center {
  text-align: left;
  width: 80%;
}

.down {
  z-index: -1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .parent-quote {
    font-size: 12px;
    line-height: 1.5;
  }

  .center {
    text-align: center;
    width: 80%;
  }
}
</style>
