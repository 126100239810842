<template>
  <!-- HERO PAGE AND NAV-BAR -->
  <Herowall></Herowall>
  <!-- <Hero></Hero> -->

  <!-----------SERVICES SECTION------------->
  <v-container>
    <v-sheet elevation="16" class="raised">
      <Services></Services>

      <About></About>

      <Word></Word>

      <Parallax
        :height="350"
        title="HOME OF SOLUTIONS"
        content="We recognize the need, let us assist."
        image="/images/us.png"
      ></Parallax>

      <Timeline />

      <!-- <hr class="hr-dotted" /> -->

      <Stats />

      <ChooseUs></ChooseUs>

      <Testimonial></Testimonial>

      <TutorList></TutorList>

      <TheMessage></TheMessage>
    </v-sheet>
  </v-container>

  <ContactUs></ContactUs>

  <Footer></Footer>
</template>

<script setup>
// defineRouteRules({
//   prerender: true
// })
import { useTheme } from "vuetify";
const theme = useTheme();
function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? "light" : "dark";
}
</script>

<script>
import "animate.css";
export default {
  data() {
    return {
      requestForm: false,
      products: null,
      length: 3,
      onboarding: 0,
      responsiveOptions: [
        {
          breakpoint: "1400px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "1199px",
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: "767px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },

  methods: {
    formToggle() {
      this.requestForm = !this.requestForm;
    },
  },
};
</script>

<style scoped>
.title {
  /* padding-top: 20vh; */
  /* font-size: 2.3vh; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.raised {
  position: relative;
  z-index: 2;
  bottom: 45px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .paragraph {
    font-size: 0.99rem;
  }

  .raised {
    position: static;
  }

  .title {
    font-size: 1.7vh;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Landscape */
@media only screen and (orientation: landscape) {
}
</style>
