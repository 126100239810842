<template>
  <h4 class="text-h4 font-weight-bold text-medium-emphasis">The Stats</h4>
  <div class="py-10 my-8">
    <v-row class="py-10">
      <v-col cols="12" lg="4">
        <div class="text-center text-lg-h6 text-body-1">
          <v-icon
            :variant="variant"
            :color="icon"
            :size="size"
            icon="mdi-account-group-outline"
          ></v-icon>
          <h5><CountUp :endValue="201" :duration="3000" /></h5>
          <h2 class="text-h5 text-medium-emphasis">Students</h2>
        </div>
      </v-col>
      <v-divider class="border-opacity-100" vertical></v-divider>
      <v-col cols="12" lg="4">
        <div class="text-center text-lg-h6 text-body-1">
          <v-icon
            :color="icon"
            :size="size"
            icon="mdi-human-male-board"
          ></v-icon>
          <h5 class=""><CountUp :endValue="501" :duration="3000" /></h5>
          <h2 class="text-h5 text-medium-emphasis">Lessons</h2>
        </div>
      </v-col>
      <v-divider class="border-opacity-100" vertical></v-divider>
      <v-col cols="12" lg="4">
        <div class="text-center text-lg-h6 text-body-1">
          <v-icon
            :color="icon"
            :size="size"
            icon="mdi-account-school-outline"
          ></v-icon>
          <h5><CountUp :endValue="81" :duration="3000" /></h5>
          <h2 class="text-h5 text-medium-emphasis">Tutors</h2>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Stats",
  data() {
    return {};
  },

  methods: {},

  props: {
    bg: String,
    icon: String,
    size: Number,
    variant: String,
  },
};
</script>

<style scoped></style>
